'use client';
import React from 'react';

import Container from '@src/components/Shared/Container';

import { CompanyStatsPropTypes } from './TrustedbyStats.types';

import useCounterAnimation from './useCounterAnimation';

const CompanyStats: React.FC<CompanyStatsPropTypes> = ({ id }) => {
  const counters = [
    { start: 0, end: 90, id: 1 },
    { start: 0, end: 90, id: 2 },
    { start: 0, end: 20, id: 3 },
    { start: 0, end: 200, id: 4 },
    { start: 0, end: 100, id: 5 },
    { start: 0, end: 50, id: 6 },
  ];
  const { onHandleAddCounters } = useCounterAnimation({
    counters,
  });

  return (
    <Container id={id} background='bg-[#FF7A00]' className='4xl:px-[0]'>
      <div className='grid grid-cols-2 gap-[2.3rem] py-[2.8rem] pt-[3rem] sm:grid-cols-3 sm:gap-x-[0] md:grid-cols-6 lg:grid-cols-7'>
        <div className='col-start-1 col-end-3 row-start-1 mb-[1rem]	text-center text-[white] sm:col-end-4 sm:text-center md:col-end-7 lg:col-start-1 lg:col-end-1 lg:m-[unset] lg:self-center lg:text-left'>
          <p className='text-[2.2rem] font-[400] leading-[1] xl:text-[3.2rem]'>Trusted by</p>
        </div>

        <div className=' border-b-[0.3rem] border-[#E04F00] sm:border-b-[0] lg:border-l-[0.3rem] xl:border-l-[0.3rem]'>
          <div className='pb-[1.3rem] pr-[0] text-[white] sm:pb-[0] sm:pl-[1rem] sm:pr-[1rem] lg:pl-[2.4rem] lg:pr-[0]'>
            <div className=''>
              <span
                ref={onHandleAddCounters}
                className='mb-[1.6rem] inline-block text-[3.2rem] font-[700] leading-[1] text-[white] md:text-[3rem] 2xl:text-[5rem] 3xl:text-[6.4rem]'
              >
                0
              </span>
              <span className='mb-[1.6rem] inline-block text-[3.2rem] font-[700] leading-[1] text-[white] md:text-[3rem] 2xl:text-[5rem] 3xl:text-[6.4rem]'>
                %+
              </span>
            </div>
            <p className='mb-[1rem] text-[1.6rem] font-[600] leading-[1] text-[white] md:text-[1.4rem] lg:text-[1.6rem] 2xl:text-[1.8rem] 3xl:text-[2rem]'>
              <span className='font-[400] leading-[1]'>of all</span> Major League
            </p>
            <p className='mb-[1rem] text-[1.6rem] font-[400] leading-[1] text-[white] md:text-[1.4rem] lg:text-[1.6rem] 2xl:text-[1.8rem] 3xl:text-[2rem]'>
              Franchises
            </p>
          </div>
        </div>

        <div className=' border-b-[0.3rem] border-[#E04F00] sm:border-b-[0] sm:border-l-[0.3rem] md:border-l-[0.3rem] xl:border-l-[0.3rem]'>
          <div className=' pb-[1.3rem] pr-[0] text-[white] sm:pb-[0] sm:pl-[1rem] sm:pr-[1rem] lg:pl-[2.4rem] lg:pr-[0]'>
            <div className=''>
              <span
                ref={onHandleAddCounters}
                className='mb-[1.6rem] inline-block text-[3.2rem] font-[700] leading-[1] text-[white] md:text-[3rem] 2xl:text-[5rem] 3xl:text-[6.4rem]'
              >
                0
              </span>
              <span className='mb-[1.6rem] inline-block text-[3.2rem] font-[700] leading-[1] text-[white] md:text-[3rem] 2xl:text-[5rem] 3xl:text-[6.4rem]'>
                %+
              </span>
            </div>
            <p className='mb-[1rem] text-[1.6rem] font-[600] leading-[1] text-[white] md:text-[1.4rem] lg:text-[1.6rem] 2xl:text-[1.8rem] 3xl:text-[2rem]'>
              <span className='font-[400] leading-[1]'>of all</span> Power 5
            </p>
            <p className='mb-[1rem] text-[1.6rem] font-[400] leading-[1] text-[white] md:text-[1.4rem] lg:text-[1.6rem] 2xl:text-[1.8rem] 3xl:text-[2rem]'>
              NCAA Colleges
            </p>
          </div>
        </div>

        <div className=' border-b-[0.3rem] border-[#E04F00] sm:border-b-[0] sm:border-l-[0.3rem] md:border-l-[0.3rem] xl:border-l-[0.3rem]'>
          <div className='inline-block  pb-[1.3rem] pr-[0] text-[white] sm:pb-[0] sm:pl-[1rem] lg:pl-[2.4rem] lg:pr-[0]'>
            <span
              ref={onHandleAddCounters}
              className='mb-[1.6rem] inline-block text-[3.2rem] font-[700] leading-[1] text-[white] md:text-[3rem] 2xl:text-[5rem] 3xl:text-[6.4rem]'
            >
              0
            </span>
            <p className='mb-[1rem] text-[1.6rem] font-[600] leading-[1] text-[white] md:text-[1.4rem] lg:text-[1.6rem] 2xl:text-[1.8rem] 3xl:text-[2rem]'>
              Premier League
            </p>
            <p className='mb-[1rem] text-[1.6rem] font-[400] leading-[1] text-[white] md:text-[1.4rem] lg:text-[1.6rem] 2xl:text-[1.8rem] 3xl:text-[2rem]'>
              Teams
            </p>
          </div>
        </div>

        <div className=' border-b-[0.3rem] border-[#E04F00] sm:border-b-[0] md:border-l-[0.3rem] xl:border-l-[0.3rem]'>
          <div className='pb-[1.3rem] pr-[0] text-[white] sm:pb-[0] sm:pl-[1rem] lg:pl-[2.4rem] lg:pr-[0]'>
            <div className=''>
              <span
                ref={onHandleAddCounters}
                className='mb-[1.6rem] inline-block text-[3.2rem] font-[700] leading-[1] text-[white] md:text-[3rem] 2xl:text-[5rem] 3xl:text-[6.4rem]'
              >
                0
              </span>
              <span className='mb-[1.6rem] inline-block text-[3.2rem] font-[700] leading-[1] text-[white] md:text-[3rem] 2xl:text-[5rem] 3xl:text-[6.4rem]'>
                +
              </span>
            </div>
            <p className='mb-[1rem] text-[1.6rem] font-[600] leading-[1] text-[white] md:text-[1.4rem] lg:text-[1.6rem] 2xl:text-[1.8rem] 3xl:text-[2rem]'>
              UEFA Member
            </p>
            <p className='mb-[1rem] text-[1.6rem] font-[400] leading-[1] text-[white] md:text-[1.4rem] lg:text-[1.6rem] 2xl:text-[1.8rem] 3xl:text-[2rem]'>
              Teams
            </p>
          </div>
        </div>

        <div className=' border-[#E04F00] sm:border-b-[0] sm:border-l-[0.3rem] md:border-l-[0.3rem] xl:border-l-[0.3rem]'>
          <div className='pr-[0] text-[white] sm:pb-[0] sm:pl-[1rem] sm:pr-[1rem] lg:pl-[2.4rem] lg:pr-[0]'>
            <div className=''>
              <span
                ref={onHandleAddCounters}
                className='mb-[1.6rem] inline-block text-[3.2rem] font-[700] leading-[1] text-[white] md:text-[3rem] 2xl:text-[5rem] 3xl:text-[6.4rem]'
              >
                0
              </span>
              <span className='mb-[1.6rem] inline-block text-[3.2rem] font-[700] leading-[1] text-[white] md:text-[3rem] 2xl:text-[5rem] 3xl:text-[6.4rem]'>
                +
              </span>
            </div>
            <p className='mb-[1rem] text-[1.6rem] font-[600] leading-[1] text-[white] md:text-[1.4rem] lg:text-[1.6rem] 2xl:text-[1.8rem] 3xl:text-[2rem]'>
              National Sporting
            </p>
            <p className='mb-[1rem] text-[1.6rem] font-[400] leading-[1] text-[white] md:text-[1.4rem] lg:text-[1.6rem] 2xl:text-[1.8rem] 3xl:text-[2rem]'>
              Organizations
            </p>
          </div>
        </div>

        <div className=' border-[#E04F00] sm:border-b-[0] sm:border-l-[0.3rem] md:border-l-[0.3rem] xl:border-l-[0.3rem]'>
          <div className='pr-[0] text-[white] sm:pb-[0] sm:pl-[1rem] sm:pr-[1rem] lg:pl-[2.4rem] lg:pr-[0]'>
            <div className=''>
              <span
                ref={onHandleAddCounters}
                className='mb-[1.6rem] inline-block text-[3.2rem] font-[700] leading-[1] text-[white] md:text-[3rem] 2xl:text-[5rem] 3xl:text-[6.4rem]'
              >
                0
              </span>
              <span className='mb-[1.6rem] inline-block text-[3.2rem] font-[700] leading-[1] text-[white] md:text-[3rem] 2xl:text-[5rem] 3xl:text-[6.4rem]'>
                +
              </span>
            </div>
            <p className='mb-[1rem] text-[1.6rem] font-[600] leading-[1] text-[white] md:text-[1.4rem] lg:text-[1.6rem] 2xl:text-[1.8rem] 3xl:text-[2rem]'>
              Defence
            </p>
            <p className='mb-[1rem] text-[1.6rem] font-[400] leading-[1] text-[white] md:text-[1.4rem] lg:text-[1.6rem] 2xl:text-[1.8rem] 3xl:text-[2rem]'>
              Departments
            </p>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default CompanyStats;

'use client';

import React from 'react';

import { useTypedAppSelector } from '@src/redux/store/store.types';

import scrollTo from '@src/utils/scrollTo';

import { ScrollToContainerPropTypes } from './ScrollToContainer.types';

const ScrollToContainer: React.FC<ScrollToContainerPropTypes> = ({ children }) => {
  const { expectedAssets, loadedAssets } = useTypedAppSelector((state) => state.loaderRXS);
  const [initialRender, setInitialRender] = React.useState(0);

  const heightOfTopNavigation = 74;

  React.useEffect(() => {
    const hash = window.location.hash;

    const allLoaded = () =>
      expectedAssets.size > 0 && [...expectedAssets].every((id) => loadedAssets.has(id));

    if (hash && allLoaded()) scrollTo({ target: hash, offsetY: heightOfTopNavigation })();

    setInitialRender(1);
  }, [loadedAssets, expectedAssets]);

  React.useEffect(() => {
    const hash = window.location.hash;

    if (initialRender === 1 && expectedAssets.size < 1 && hash) {
      scrollTo({ target: hash, offsetY: heightOfTopNavigation })();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialRender]);

  return <>{children}</>;
};

export default ScrollToContainer;
